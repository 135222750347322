import React from "react";
import Helmet from "react-helmet";

import Container from "@components/Container";
import Layout from "@components/Layout";

export default () => {
    return (
        <Layout pageName="abnt">
            <Helmet>
                <title>SLS & Advogados Associados - Política e Privacidade de dados</title>
            </Helmet>
            <div className="office">
                <Container>
                    <h3>POLÍTICA DE PRIVACIDADE DE DADOS</h3>

                    <a href="../privacidade.pdf">Baixar versão em PDF</a>
                    <br />
                    <br />
                    <p>
            Na <b>Sanches Lopes, Sales &amp; Advogados Associados</b>, estamos comprometidos com o
            cumprimento das leis que protegem a privacidade dos dados de clientes e do público em
            geral. Em nossa Política de Privacidade e Proteção de Dados explicamos que ao utilizar
            nossos serviços, coletaremos,
                    </p>
                    <p>
            utilizaremos e compartilharemos seus Dados Pessoais nas formas descritas nesta Política,
            seguindo as diretrizes estabelecidas na Lei Federal nº 13.709/2018 (Lei Geral de
            Proteção de Dados Pessoais - LGPD) e as demais normas do ordenamento jurídico brasileiro
            aplicáveis.
                    </p>
                    <p>
            Dessa forma, a <b>Sanches </b>
                        <b></b>
                        <b>Lopes, </b>
                        <b></b>
                        <b>Sales </b>
                        <b></b>
                        <b>&amp; </b>
                        <b></b> <b>Advogados </b>
                        <b></b>
                        <b>Associados</b>,
                    </p>
                    <p>
            inscrita no CNPJ/MF sob o nº 05.898.273/0001-19, no papel de Controladora de Dados,
            obriga-se a cumprir o disposto na presente Política de Privacidade.
                    </p>

                    <h3>1. INFORMAÇÕES GERAIS E DEFINIÇÕES</h3>

                    <p>
                        <b>Dado Pessoal: </b>é definido como “informação relacionada à pessoa identificada ou
            identificável”, ou seja, quando esse dado permite a
                    </p>
                    <p>
            identificação, direta ou indireta, de uma pessoa, como por exemplo: nome, sobrenome,
            data de nascimento, documentos pessoais, endereço residencial ou comercial, telefone,
            e-mail e endereço de IP.
                    </p>
                    <p>
                        <b>Dado Pessoal Sensível: </b>
            está relacionado à origem racial ou étnica,
                    </p>
                    <p>
            convicção religiosa, opinião política, filiação a sindicato ou a organização de caráter
            religioso, filosófico ou político, dado referente à saúde ou à vida sexual, dado
            genético ou biométrico, quando vinculado a sua pessoa.
                    </p>
                    <p>
                        <b>Dado anonimizado: </b>
            relativo ao titular que não possa ser identificado,
                    </p>
                    <p>
            considerando a utilização de meios técnicos razoáveis e disponíveis na ocasião de seu
            tratamento.
                    </p>
                    <p>
                        <b>Anonimização:</b>
                        <b> </b>
            utilização de meios técnicos razoáveis e disponíveis no
                    </p>
                    <p>
            momento do tratamento, por meio dos quais um dado perde a possibilidade de associação,
            direta ou indireta, a um indivíduo.
                    </p>
                    <p>
                        <b>Tratamento: </b>
            qualquer operação, como a coleta, produção, recepção, classificação, utilização, acesso,
            reprodução, transmissão, distribuição, processamento, arquivamento, armazenamento,
            eliminação, avaliação ou
                    </p>
                    <p>
            controle da informação, modificação, comunicação, transferência, difusão ou extração,
            realizada com dados pessoais.
                    </p>
                    <p>
                        <b>Consentimento: </b>
            manifestação livre, informada e inequívoca pela qual
                    </p>
                    <ul>
                        <li>
                            <p>
                titular concorda com o tratamento de seus dados pessoais para uma finalidade
                determinada.
                            </p>
                        </li>
                    </ul>
                    <p>
                        <b>Titular: </b>
            pessoa natural a quem se referem os dados pessoais que são
                    </p>
                    <p>objeto de tratamento.</p>
                    <p>
                        <b>Controlador:</b>
                        <b> </b>
            pessoa natural ou jurídica, de direito público ou privado, a quem competem as decisões
            referentes ao tratamento de dados.
                    </p>
                    <p>
                        <b>Operador:</b>
                        <b> </b>
            pessoa natural ou jurídica, de direito público ou privado, que realiza o tratamento de
            dados em nome do controlador.
                    </p>
                    <p>
                        <b>Encarregado</b>
                        <b> </b>
                        <b>de</b>
                        <b> </b>
                        <b>Proteção</b>
                        <b> </b>
                        <b>de</b>
                        <b> </b>
                        <b>Dados:</b>
                        <b> </b>
            pessoa indicada pelo controlador e operador para atuar como canal de comunicação entre o
            controlador, os titulares dos dados e a Autoridade Nacional de Proteção de Dados.
                    </p>
                    <h1>Autoridade Nacional de Proteção de Dados (ANPD): Órgão</h1>
                    <p>
            responsável por zelar, implementar e fiscalizar o cumprimento da legislação de proteção
            de dados pessoais.
                    </p>
                    <p>
                        <b>Banco</b>
                        <b> </b>
                        <b>de</b>
                        <b> </b>
                        <b>dados:</b>
                        <b> </b>
            conjunto estruturado de dados pessoais, estabelecido
                    </p>
                    <p>em um ou em vários locais, em suporte eletrônico ou físico.</p>
                    <p>
                        <b>Uso compartilhado de dados: </b>
            comunicação, difusão, transferência internacional, interconexão de dados pessoais ou
            tratamento compartilhado
                    </p>
                    <p>
            de bancos de dados pessoais por órgãos e entidades públicas no cumprimento de suas
            competências legais, ou entre esses entes privados, reciprocamente, com autorização
            específica, para uma ou mais modalidades de tratamento permitidas por esses entes
            públicos, ou entre entes privados.
                    </p>
                    <p>
                        <em>
                            <b>Cookies:</b>
                        </em>
                        <em>
                            <b> </b>
                        </em>
            São arquivos ou informações que podem ser armazenadas em
                    </p>
                    <p>
            seus dispositivos quando você visita os <em>websites </em>ou utiliza os serviços{" "}
                        <em>online</em>
                    </p>
                    <p>da Organização.</p>
                    <p>
                        <b>Bloqueio: </b>
            suspensão temporária de qualquer operação de tratamento, mediante guarda do dado pessoal
            ou do banco de dados.
                    </p>
                    <p>
                        <b>Eliminação: </b>
            exclusão de dado ou de conjunto de dados armazenados em banco de dados,
            independentemente do procedimento empregado.
                    </p>

                    <h3>2. COLETA E USO DE INFORMAÇÕES PESSOAIS</h3>

                    <h4>2.1. COLETA DE DADOS</h4>

                    <p>
            O Usuário fornece seus dados e informações de forma consciente e voluntária por meio de
            formulário de registro (<em>online</em>
                        <em> </em>ou <em>offline</em>) em nosso site, e-mail, SMS, aplicativos de mensagens
            instantâneas, redes sociais e nas sedes de nossa empresa advocatícia.
                    </p>
                    <p>
            Quando o usuário realiza o cadastro ou preenche formulários oferecidos pela{" "}
                        <b>Sanches Lopes, Sales &amp; Advogados Associados</b>, inclusive no site operado pela
            mesma, temos o cuidado para que apenas os dados estritamente necessários e adequados
            sejam coletados, a fim de atingir a finalidade legítima, seja para prestação de serviço
            judicial, extrajudicial ou consultivo. Os Dados Pessoais serão mantidos em sigilo e
            serão utilizados apenas para o
                    </p>
                    <p>propósito que motivou o cadastro.</p>
                    <p>Os dados pessoais são recebidos dos seguintes titulares:</p>
                    <ul>
                        <li>
                            <p>Consulentes;</p>
                        </li>
                        <li>
                            <p>Clientes contratantes;</p>
                        </li>
                        <li>
                            <p>Colaboradores, contratados e fornecedores;</p>
                        </li>
                        <li>
                            <p>Candidatos a vagas de emprego ou estágio;</p>
                        </li>
                        <li>
                            <p>Usuários que realizam acesso ou cadastro em nosso site: <b>www.slsadvogados.com.br</b></p>
                        </li>

                    </ul>

                    <h4>2.2. NATUREZA E FINALIDADE DOS DADOS TRATADOS</h4>
                    <p>
            Na <b>Sanches Lopes, Sales &amp; Advogados Associados </b>
            realizamos o tratamento de Dados Pessoais e Dados Pessoais Sensíveis de nossos
            colaboradores, clientes, partes adversas e terceiros (ex. testemunhas, peritos,
            assistentes técnicos, serventuários da justiça, juízes, advogados) para execução de
            pré-contrato, contrato, cumprimento de dever legal e
                    </p>
                    <p>representação em processos administrativos e judiciais.</p>

                    <h4>2.3. CATEGORIA DE DADOS PESSOAIS COLETADOS</h4>
                    <ul>
                        <li>
                            <p>
                                <b>Dados de identificação:</b>
                                <b> </b>
                Nome, sobrenome, endereços, pseudônimo, local e data de nascimento, telefone,
                e-mail, fotografia, documentos pessoais (cédula de identidade, CPF, CNH, título de
                eleitor, reservista,
                            </p>
                        </li>
                    </ul>
                    <p>
            certidão de nascimento, certidão de casamento, certidão de óbito, CTPS, cartão do SUS,
            cartão cidadão, cartão de plano de saúde, PIS/NIS e passaporte), comprovante de
            endereço, detalhes bancários, assinatura, informações de contato;
                    </p>
                    <ul>
                        <li>
                            <p>
                                <b>Dados profissionais:</b>
                                <b> </b>
                Local de trabalho, cargo e remuneração;
                            </p>
                        </li>
                        <li>
                            <p>
                                <b>Dados financeiros: </b>
                Dados fiscais, extratos bancários, investimento e histórico de investimentos;
                            </p>
                        </li>
                        <li>
                            <p>
                                <b>Dados</b>
                                <b> </b>
                                <b>de</b>
                                <b> </b>
                                <b>saúde:</b>
                                <b> </b>
                Peso, comorbidades, prontuários médicos, resultados de exames, tratamentos
                realizados e tratamentos pretendidos.
                            </p>
                        </li>
                    </ul>

                    <h4>2.4. PRINCIPAIS FINALIDADES DOS TRATAMENTOS DE DADOS</h4>
                    <p>
            Na <b>Sanches</b>
                        <b> </b>
                        <b>Lopes,</b>
                        <b> </b>
                        <b>Sales</b>
                        <b> </b>
                        <b>&amp;</b>
                        <b> </b>
                        <b>Advogados</b> <b> </b>
                        <b>Associados</b>
                        <b> </b>observamos a boa- fé e os princípios estabelecidos pela legislação nas
            atividades de tratamento de dados pessoais.
                    </p>
                    <p>
                        <b>Finalidade: </b>
            realização do tratamento para propósitos legítimos, específicos, explícitos e informados
            ao titular, sem possibilidade de tratamento posterior de forma incompatível com essas
            finalidades;
                    </p>
                    <p>
                        <b>Adequação: </b>
            compatibilidade do tratamento com as finalidades informadas ao titular, de acordo com o
            contexto do tratamento;
                    </p>
                    <p>
                        <b>Necessidade: </b>
            limitação do tratamento ao mínimo necessário para a realização de suas finalidades, com
            abrangência dos dados pertinentes,
                    </p>
                    <p>proporcionais e não excessivos em relação às finalidades do tratamento de dados;</p>
                    <p>
                        <b>Livre acesso: </b>
            garantia, aos titulares, de consulta facilitada e gratuita sobre a forma e a duração do
            tratamento, bem como sobre a integralidade de seus dados pessoais;
                    </p>
                    <p>
                        <b>Qualidade dos dados: </b>
            garantia, aos titulares, de exatidão, clareza, relevância e atualização dos dados, de
            acordo com a necessidade e para o cumprimento da finalidade de seu tratamento;
                    </p>
                    <p>
                        <b>Transparência:</b>
                        <b> </b>
            garantia, aos titulares, de informações claras, precisas e facilmente acessíveis sobre a
            realização do tratamento e os respectivos agentes de tratamento, observados os segredos
            comercial e industrial;
                    </p>
                    <p>
                        <b>Segurança: </b>
            utilização de medidas técnicas e administrativas aptas a proteger os dados pessoais de
            acessos não autorizados e de situações acidentais ou ilícitas de destruição, perda,
            alteração, comunicação ou difusão;
                    </p>
                    <p>
                        <b>Prevenção:</b>
                        <b> </b>
            adoção de medidas para prevenir a ocorrência de danos em virtude do tratamento de dados
            pessoais;
                    </p>
                    <p>
                        <b>Não</b>
                        <b> </b>
                        <b>discriminação:</b>
                        <b> </b>
            impossibilidade de realização do tratamento para fins discriminatórios ilícitos ou
            abusivos;
                    </p>
                    <p>
                        <b>Responsabilização</b>
                        <b> </b>
                        <b>e</b>
                        <b> </b>
                        <b>prestação</b>
                        <b> </b>
                        <b>de</b>
                        <b> </b>
                        <b>contas:</b>
                        <b> </b>
            demonstração, pelo agente, da adoção de medidas eficazes e capazes de comprovar a
            observância e o cumprimento das normas de proteção de dados pessoais e, inclusive, da
                    </p>
                    <p>eficácia dessas medidas.</p>
                    <p>
            Neste sentido, coletamos os seus dados pessoais e realizamos o tratamento das suas
            informações de acordo com a seguintes finalidades específicas:
                    </p>
                    <ul style={{ paddingLeft: "50px" }}>
                        <li>I - Responder ou enviar informações administrativas;</li>
                        <li>II - Prestar o serviço no qual foi contratado;</li>
                        <li>III - Responder a solicitação de autoridades por ordens judiciais;</li>
                        <li>
              IV - Defender os interesses da <b>Sanches Lopes, Sales &amp; Advogados Associados </b>
              em processo judicial ou administrativo;
                        </li>
                        <li>
                V - Enviar mensagens eletrônicas sobre os serviços prestados e/ou com informações
                jurídicas, àqueles que solicitarem via formulário de consentimento no site;
                        </li>
                        <li>
                        VI - Enviar mensagens eletrônicas (<em>opt-out</em>) para quem possui alguma relação
            comercial com nossa empresa, permitido sempre o cancelamento.
                        </li>
                    </ul>

                    <h4>2.5. BASE-LEGAL PARA TRATAMENTO DE DADOS PESSOAIS</h4>

                    <p>
            A legislação que permite e controla o ato de tratamento de dados é a Lei Federal nº
            13.709/2018 (Lei Geral de Proteção de Dados Pessoais – LGPD). A referida lei fundamenta
            a legalidade do tratamento dos seus dados pessoais para as finalidades que indicamos no
            tópico anterior, as quais se amoldam às seguintes autorizações legais:
                    </p>

                    <ul>
                        <li>
                            <p>Consentimento do titular (inciso I do artigo 7º e 14 da Lei nº 13.709/18);</p>
                        </li>
                        <li>
                            <p>
                Execução do contrato ou de procedimentos preliminares relacionados ao contrato do
                cliente (inciso V do artigo 7º da Lei nº 13.709/18);
                            </p>
                        </li>
                        <li>
                            <p>Obrigação legal ou regulatória (inciso II do artigo 7º da Lei nº 13.709/18);</p>
                        </li>
                        <li>
                            <p>
                Defesa e utilização em processos judiciais (inciso VI do artigo 7º da Lei nº
                13.709/18);
                            </p>
                        </li>
                        <li>
                            <p>Legítimo interesse (inciso IX do artigo 7º da Lei nº 13.709/18).</p>
                        </li>
                    </ul>

                    <h4>2.6. COMPARTILHAMENTO E TRATAMENTO DE DADOS PESSOAIS</h4>

                    <p>
            A <b>Sanches Lopes, Sales &amp; Advogados Associados</b>não disponibilizará Dados
            Pessoais coletados para intermediários de lista de e-mail sem seu expresso
            consentimento.
                    </p>

                    <p>
            Alguns dados dos titulares poderão ser utilizados para o envio de<em>newsletter</em>,
            permanecendo sempre a<b>Sanches Lopes, Sales &amp;</b>
                        <b> </b> <b>Advogados Associados </b>como controlador dos dados pessoais. Seus dados não
            serão
                    </p>
                    <p>transferidos a terceiros sem prévia autorização.</p>

                    <h3>3. RETENÇÃO DE DADOS</h3>

                    <p>
            Todos os dados fornecidos para a <b>Sanches</b>
                        <b> </b>
                        <b>Lopes,</b>
                        <b> </b>
                        <b>Sales</b>
                        <b> </b>
                        <b>&amp;</b>
                        <b> </b> <b>Advogados Associados </b>serão retidos enquanto o cadastro do Usuário
            estiver ativo,
                    </p>
                    <p>
            conforme seja necessário para a prestação de serviços, pelo tempo que for necessário
            para alcançar as finalidades para as quais eles foram coletados ou para cumprir com
            obrigações legais aplicáveis. Os dados retidos serão armazenados até eventual
            requerimento de exclusão por parte do usuário, ressalvada a possibilidade de manutenção
            dos dados na forma do artigo 16 da LGPD<a name="sdfootnote1anc"
                            href="#sdfootnote1sym"><sup>1</sup></a>.
                    </p>

                    <h3>4. DIREITOS DO TITULAR</h3>

                    <p>
            A LGPD garante ao titular diversos direitos relacionados aos seus dados, entre eles:
                    </p>
                    <ol type="a" style={{ paddingLeft: "50px" }}>
                        <li>
                            <p>Acessar seus Dados Pessoais;</p>
                        </li>
                        <li>
                            <p>
                Corrigir ou solicitar a correção de Dados Pessoais incompletos, inexatos ou
                desatualizados;
                            </p>
                        </li>
                        <li>
                            <p>
                Solicitar a anonimização, bloqueio ou eliminação de Dados Pessoais desnecessários e
                excessivos;
                            </p>
                        </li>
                        <li>
                            <p>
                Solicitar a eliminação dos Dados Pessoais coletados e utilizados com base no seu
                consentimento;
                            </p>
                        </li>
                        <li>
                            <p>
                Obter informações sobre as entidades públicas ou privadas com as quais
                compartilhamos os seus Dados Pessoais;
                            </p>
                        </li>
                        <li>
                            <p>
                Negar o consentimento ao tratamento. Nesse caso, lhe informaremos sobre as
                consequências da não realização de tal atividade;
                            </p>
                        </li>
                        <li>
                            <p>Revogar o consentimento referente ao tratamento de dados.</p>
                        </li>
                    </ol>

                    <h3>5. UTILIZAÇÃO DE
                        <em>
                                    COOKIES
                        </em>
                    </h3>
                    <p>
            A cultura de privacidade e proteção de dados está presente na<b>Sanches </b>
                        <b>Lopes,</b>
                        <b> </b>
                        <b>Sales</b>
                        <b> </b>
                        <b>&amp;</b>
                        <b> </b>
                        <b>Advogados</b>
                        <b> </b> <b>Associados</b>, portanto valorizamos a transparência e honestidade quanto a
            coleta e uso dos seus dados.
                    </p>
                    <p>
            Informamos que fazemos uso de <em>Cookies</em>
                        <em> </em>e tecnologias semelhantes, para coletar dados básicos, como identificadores de
            dispositivos.
                    </p>
                    <p>
                        <em>Cookies </em>
            são arquivos ou informações que podem ser armazenadas em seus dispositivos ou inseridos
            no seu navegador (Mozzila, Chrome, etc.) quando você visita os <em>websites </em>ou
            utiliza os serviços <em>online </em>da organização, onde são permitidos ativar
            funcionalidades através de informações básicas sobre o seu dispositivo ou sobre o
            titular.
                    </p>
                    <p>
            Os <em>Cookies </em>usados em nossa plataforma <em>web </em>tem as seguintes funções:
                    </p>
                    <ul>
                        <li>
                            <p>
                                <em>
                                    <b>Cookies</b>
                                </em>
                                <em>
                                    <b> </b>
                                </em>
                                <b>estritamente</b>
                                <b> </b>
                                <b>necessários:</b>
                                <b> </b>
                Essenciais para o funcionamento e operacionalização do <em>site</em>, permitindo que
                navegue e faça uso de todas as
                            </p>
                        </li>
                    </ul>
                    <p>funcionalidades.</p>
                    <ul>
                        <li>
                            <p>
                                <em>
                                    <b>Cookies</b>
                                </em>
                                <em>
                                    <b> </b>
                                </em>
                                <b>de</b>
                                <b> </b>
                                <b>desempenho:</b>
                                <b> </b>
                Fornecem informações estatísticas do uso
                            </p>
                        </li>
                    </ul>
                    <p>
            do <em>site</em>, ou seja, análise da <em>web</em>, fornecendo informações sobre as
            áreas visitadas, o tempo de visita ao <em>site </em>e qualquer mensagem de erro que
            possa ocorrer.
                    </p>
                    <ul>
                        <li>
                            <p>
                                <em>
                                    <b>Cookies </b>
                                </em>
                                <b>de funcionalidade: </b>
                Permitem o fornecimento de uma funcionalidade e personalização aprimoradas, como
                lembrar suas escolhas
                            </p>
                        </li>
                    </ul>
                    <p>para proporcionar uma melhor experiência.</p>
                    <ul>
                        <li>
                            <p>
                                <em>
                                    <b>Cookies </b>
                                </em>
                                <b>de </b>
                                <b>publicidade: </b>
                são utilizados para criar perfis ou personalizar o conteúdo que seja mais relevante
                ao interesse dos titulares.
                            </p>
                        </li>
                    </ul>
                    <h3>6. SEGURANÇA DA INFORMAÇÃO</h3>
                    <p>
            Prezamos pela segurança da informação, aplicando as medidas organizacionais, técnicas e
            administrativas adequadas a fim de proteger os dados pessoais, aos quais temos controle.
                    </p>
                    <p>
            Está restrito apenas aos colaboradores e pessoas autorizadas, o acesso às informações
            coletadas.
                    </p>
                    <p>
            Caso tenha motivos para entender que a sua interação com a<b>Sanches Lopes,</b>
                        <b> </b>
                        <b>Sales</b>
                        <b> </b>
                        <b>&amp;</b>
                        <b> </b>
                        <b>Advogados</b>
                        <b> </b>
                        <b>Associados</b> <b> </b>tenha deixado de ser segura ou seus dados tenham sido
            comprometidos, notifique-nos do problema prontamente,
                    </p>
                    <p>entrando em contato pelos meios informados no final desta Política.</p>

                    <h3>7. CONTATO</h3>

                    <p>
            A <b>Sanches</b>
                        <b> </b>
                        <b>Lopes,</b>
                        <b> </b>
                        <b>Sales</b>
                        <b> </b>
                        <b>&amp;</b>
                        <b> </b>
                        <b>Advogados</b> <b> </b>
                        <b>Associados</b>
                        <b> </b>nomeou Luciano de Sales como Encarregado de Proteção de Dados. Para qualquer
            informação, ou exercício dos seus direitos como titular dos dados pessoais, entre em
            contato com o encarregado no seguinte endereço Rua Benedito Pereira de Oliveira, nº
            277-W, Centro, CEP: 78.300-000, cidade de Tangará da Serra/MT ou através
                    </p>
                    <p>
            do e-mail
                        <a href="mailto:encarregado@slsadvogados.com.br">
                            <u> encarregado@slsadvogados.com.br</u>
                        </a>
            .
                    </p>
                    <h3>8. VERSÃO E REVISÕES À POLÍTICA</h3>
                    <p>
            Nossa Política de Proteção e Privacidade de Dados Pessoais está em sua versão nº 1.0.
                    </p>
                    <p>
            Nos reservamos no direito de fazer alterações às nossas práticas e a esta Política a
            qualquer tempo. Caso a <b>Sanches Lopes, Sales &amp; Advogados Associados </b>modifique
            esta Política de Privacidade, tais alterações serão disponibilizadas de forma visível no{" "}
                        <em>site</em>. Esta Política é válida a partir de <b>08/03/2021</b>.
                    </p>
                    <hr />
                    <div id="sdfootnote1" style={{ fontSize: "1.0rem" }}>
                        <p>
                            <a name="sdfootnote1sym" href="#sdfootnote1anc">1 </a>
            Art. 16. Os dados pessoais serão eliminados após o término de seu tratamento, no âmbito
            e nos limites técnicos das atividades, autorizada a conservação para as seguintes
            finalidades:
                        </p>

                        <ol type="I" style={{ paddingLeft: "50px" }}>
                            <li>
                                <p>- cumprimento de obrigação legal ou regulatória pelo controlador;</p>
                            </li>
                            <li>
                                <p>
                - estudo por órgão de pesquisa, garantida, sempre que possível, a anonimização dos
                dados pessoais;
                                </p>
                            </li>
                            <li>
                                <p>
                - transferência a terceiro, desde que respeitados os requisitos de tratamento de
                dados dispostos nesta Lei; ou
                                </p>
                            </li>
                            <li>
                                <p>
                - uso exclusivo do controlador, vedado seu acesso por terceiro, e desde que
                anonimizados os dados.
                                </p>
                            </li>
                        </ol>
                    </div>
                </Container>
            </div>
        </Layout>
    );
};
